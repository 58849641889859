const localStorage = typeof window !== 'undefined' && window.localStorage

module.exports = {
  getStorageItem:(item) => {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  },
  setStorageItem:(item, value) => {
    typeof localStorage.setItem !== 'undefined'
        ? localStorage.setItem(item, value)
        : console.log('no localStorage')
  },
  removeStorageItem: (item) => {
    typeof localStorage.removeItem !== 'undefined'
        ? localStorage.removeItem(item)
        : console.log('no localStorage')
  }
}