import React, { useState, useEffect, useContext } from "react";
import { FindDealerContext }  from "./context/findDealerContext";
import view from "./findDealer.module.scss";
import MapMarkerIcon from './assets/mapMarkerIcon';

const { siteMetadata } = require('../../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealerNav = (props) =>{
  const [visible, setVisible] = useState(false);

  const [formZip, setFormZip] = useState('');

  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   * 
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const {searchZipCode, closestDealerCompPresent, scrollToListElement} = findDealerProps;

  const keyPress = (e, formZip) => {
    if (e.keyCode === 13) {
      searchZipCode(formZip);
    }
  }


  function holdFormZip(val){
    setFormZip(val);
  }

  function submission(e, formZip){
    if(closestDealerCompPresent === true){
      searchZipCode(formZip);
      e.preventDefault();
    }else{
      searchZipCode(formZip);
    }
    scrollToListElement();
  }

  return(
    <div className={`${view["nav-search"]}`}>
   
    {visible?
      <form className={`${view["search-box"]} ${visible && view["search-show"]}`} onSubmit={(e)=>submission(e, formZip)} autoComplete="off" style={{display:'flex', alignItems:'center', justifyContent:'center', margin:'0 auto'}}>
        <div className={view["search-input"]} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%', cursor:'pointer'}}>
          <span onClick={() => setVisible(!visible)}>&#215;</span>

          <input maxlength="5" maxlength="5" minlength="5" style={{display:'block', width:'100%',  backgroundColor:'none', color:'#adadad'}} required name="searchedZip" label="zip code" type="text" placeholder="Search Zip" wrap="hard" onChange={(e)=>holdFormZip(e.target.value)} onKeyDown={(e)=>keyPress(e, formZip)}/>
        
          <button className={view["search-icon"]} onClick={(e)=>submission(e, formZip)}>
          
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2302 16.652C18.6941 14.9414 19.5793 12.7183 19.5793 10.2897C19.5793 4.88298 15.1963 0.5 9.78967 0.5C4.38298 0.5 0 4.88298 0 10.2897C0 15.6963 4.38298 20.0793 9.78967 20.0793C11.8537 20.0793 13.7702 19.4397 15.3493 18.3484L21.501 24.5L23.2896 22.7114L17.2302 16.652ZM9.78967 3.02952C5.78 3.02952 2.52952 6.28 2.52952 10.2897C2.52952 14.2993 5.78 17.5498 9.78967 17.5498C13.7993 17.5498 17.0498 14.2993 17.0498 10.2897C17.0498 6.28 13.7993 3.02952 9.78967 3.02952Z" fill="#adadad" />
            </svg>
                
          </button>
       </div>
       
     </form>
      :
      <div onClick={(()=>setVisible(!visible))} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%', cursor:'pointer'}}>
        <MapMarkerIcon/>
        <p style={{cursor:'pointer', paddingLeft:'1em'}} >
          &nbsp;Find a Dealer
        </p>
      </div>
    }
    </div>
  );

}

export default FindDealerNav;