/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, data }) {
    const metaDescription = data.seo.MetaDesc || description
    const metaTitle = data.seo.MetaTitle || title

    const metaTags = data.seo.tags
    // Tags for Testing
    // [{"tagName": "SiteColor","tagContent":"Blue","id":"\"SiteColorBlue\"","editState":false},
    // {"tagName": "DealerID","tagContent":"314","id":"\"DealerID314\"","editState":false}]

    let tags = []
    metaTags.forEach(tag => tags.push({ "name": tag.tagName, "content": tag.tagContent }));

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `title`,
                    content: metaTitle,
                }
            ].concat(meta).concat(tags)}
        >
            <title>{metaTitle}</title>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO