import React from 'react';
import view from "../findDealer.module.scss";


const MapMarkerIcon = () => {
  return (
    <svg  width="28" height="28" viewBox="0 0 77 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Artboard1" transform="matrix(0.953613,0,0,1.00952,-8.18741,0)">
      <rect x="8.586" y="0" width="79.903" height="100.633" style={{fill:'none'}}/>
        <g transform="matrix(1.04864,0,0,0.990571,-3.6902,0.948824)">
        <path className={view["icon-fills"]} fill-rule="evenodd" clip-rule="evenodd" d="M50,0C29.3,0 12.5,16.8 12.5,37.5C12.5,54 23.2,68 38,73L48.3,97.8C49.3,100.7 50.8,100.7 51.8,97.8L62,73C76.8,68 87.5,54 87.5,37.5C87.5,16.8 70.7,0 50,0ZM50,53.9C40.9,53.9 33.6,46.6 33.6,37.5C33.6,28.4 40.9,21.1 50,21.1C59.1,21.1 66.4,28.4 66.4,37.5C66.4,46.6 59.1,53.9 50,53.9Z" fill="#E82C2A"/>
        </g>
      </g>
    </svg>
  )
}

export default MapMarkerIcon;